<template>
  <section id="edit-vendor-shop">
    <b-row class="mb-2">
      <b-col
        md="8"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('editReturn') }}</h1>
        <h5 class="text-primary">
          {{ $t('configReturn') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          variant="danger"
          @click="goBack()"
        >
          {{ $t("dataGeneric.goBack") }}
        </b-button>
      </b-col>
    </b-row>
    <b-tabs
      v-if="mostrarDatos"
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t("contents.categoryData") }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showData"
        >
          <b-row class="match-height mt-2">
            <b-col>
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t("contents.categoryData") }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-form @submit.prevent="updateData(0)">
                    <b-row>
                      <b-col md="10">
                        <b-form-group
                          :label="$t('Order')"
                          :invalid-feedback="$t('requiredOrder')"
                        >
                          <div
                            disabled
                            @click="$refs['order-modal'].show()"
                          >
                            <b-form-input
                              id="orderId"
                              v-model="orderId"
                              autocomplete="new-password"
                              maxlength="130"
                              :placeholder="$t('Order')"
                              required
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        md="1"
                        class="align-items-center align-self-center "
                      >
                        <b-button
                          variant="info"
                          @click="$refs['order-modal'].show()"
                        >
                          {{ $t('Select') }}
                        </b-button>
                      </b-col>
                      <b-col md="5">
                        <b-form-group
                          :label="$t('Vendedor')"
                          :invalid-feedback="$t('requiredSeller')"
                        >
                          <b-form-input
                            id="vendor"
                            v-model="vendorName"
                            disabled
                            :placeholder="$t('Vendedor')"
                            maxlength="130"
                            autocomplete="new-password"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col md="5">
                        <b-form-group :label="$t('User')">
                          <b-form-input
                            id="user"
                            :value="cleanUsername(userName)"
                            :placeholder="$t('User')"
                            maxlength="130"
                            autocomplete="new-password"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col>
                        <b-button
                          type="submit"
                          variant="success"
                        >
                          {{ $t("dataGeneric.save") }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-overlay>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>{{ $t('settingTabs.paid') }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :show="showData"
        >
          <b-row class="match-height mt-2">
            <b-col>
              <b-card>
                <b-card-header>
                  <b-card-title>{{ $t('settingTabs.paid') }}</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-form @submit.prevent="updateData(1)">
                    <b-row>
                      <b-col md="6">
                        <b-form-group :label="$t('editContent.status')">
                          <b-form-select
                            v-model="statusPay"
                            :disabled="saveStatus == 'COM' || saveStatus == 'CAN'"
                          >
                            <b-form-select-option
                              v-for="v in Object.keys(status)"
                              :key="v"
                              :disabled="v == 'COM'"
                              :value="v"
                            >
                              {{ status[v] }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group :label="$t('methodPayment')">
                          <b-form-select
                            v-model="paymentGateway"
                            disabled
                          >
                            <b-form-select-option
                              v-for="v in Object.keys(methodPayment)"
                              :key="v"
                              :value="v"
                            >
                              {{ methodPayment[v] }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group :label="$t('refundQuantity')">
                          <b-form-input
                            id="amount"
                            v-model="amount"
                            disabled
                            :placeholder="$t('refundQuantity')"
                            maxlength="130"
                            autocomplete="new-password"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group :label="$t('idRefundPayment')">
                          <b-form-input
                            id="refund"
                            v-model="paymentId"
                            :placeholder="$t('idRefundPayment')"
                            disabled
                            maxlength="130"
                            autocomplete="new-password"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <div class="amount_info d-block">
                          <p>{{ $t('errMsgRefundGreater',{totalAmount:totalAmount}) }}</p>
                          <p>{{ $t('shippingCostMsg',{shippingCost:shippingCost}) }}</p>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <b-form-group :label="$t('refundReason')">
                          <b-form-textarea
                            id="reason"
                            v-model="reason"
                            rows="4"
                            :placeholder="$t('refundReason')"
                            autocomplete="new-password"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group :label="$t('returnProducts')">
                          <b-form-checkbox-group
                            v-model="selected"
                            class="d-flex flex-column"
                          >
                            <div
                              v-for="pr in products"
                              :key="pr.node.id"
                              class="align-items-center d-flex m-1"
                            >
                              <b-form-checkbox
                                :id="pr.node.id"
                                :value="pr.node"
                              />
                              <label
                                :for="pr.node.id"
                                style="font-size: small;"
                              >
                                {{ pr.node.itemName }} {{ pr.node.itemPrice }} € X
                              </label>
                              <b-form-input
                                :id="'stock_' + pr.node.id"
                                type="number"
                                :value="0"
                                min="0"
                                :max="pr.node.itemQuantity"
                                class="ml-1 mr-1"
                                style="width: 70px;"
                                @blur="checkStock()"
                              />
                            </div>
                          </b-form-checkbox-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-button
                      class="mt-3 ml-2"
                      type="submit"
                      variant="success"
                    >
                      {{ $t("dataGeneric.save") }}
                    </b-button>
                  </b-form>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-overlay>
      </b-tab>
    </b-tabs>

    <b-modal
      ref="order-modal"
      size="xl"
      hide-footer
      :title="$t('shopOrder')"
    >
      <div class="d-block text-center">
        <order-selector @order="selectOrder" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  showToast, messageError, cleanUsername,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'

import {
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BFormSelectOption,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormSelect,
  BTabs, BTab,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'

import OrderSelector from '@/views/common/OrderSelectorModal.vue'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    BCol,
    BCard,
    BButton,
    BFormSelect,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormCheckboxGroup,
    BFormSelectOption,
    BFormTextarea,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,
    OrderSelector,

  },

  data() {
    return {
      products: [],
      selected: [],
      next: true,
      totalAmount: 0,
      totalRefunded: 0,
      shippingCost: 0,
      vendorId: null,
      orderId: null,
      showData: false,
      vendorName: null,
      userData: getUserData(),
      id: this.$route.params.id,
      idSelectCard: null,
      methodPayment: {
        STR: 'Stripe',
        APP: 'Apple InAppPurchase',
        GOW: 'Google InAppPurchase',
      },
      vendorImageSelected: null,
      vendorImageSelectedPlaceholder: '',
      mostrarDatos: false,
      status: {
        PEN: this.$t('pending'),
        COM: this.$t('completed'),
        CAN: this.$t('cancelled'),
      },
      userId: null,
      userName: null,
      paymentId: null,
      statusPay: null,
      saveStatus: null,
      paymentGateway: null,
      reason: this.$t('requestedByClient'),
      amount: 0,
    }
  },
  watch: {
    amount() {
      if (this.amount > this.totalAmount) this.amount = this.totalAmount
    },
    selected() {
      // document.getElementById("stock_"+)
      try {
        let amount = this.shippingCost

        this.selected.forEach(element => {
          const stock = document.getElementById(`stock_${element.id}`).value
          amount += element.itemPrice * stock
        })
        this.amount = this.roundToTwoDecimals(amount)
      // eslint-disable-next-line no-empty
      } catch (error) {

      }
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    checkStock() {
      let amount = this.shippingCost
      try {
        this.selected.forEach(element => {
          const stock = document.getElementById(`stock_${element.id}`).value
          amount += element.itemPrice * stock
        })
        this.amount = this.roundToTwoDecimals(amount)
      // eslint-disable-next-line no-empty
      } catch (error) {

      }
    },
    roundToTwoDecimals(value) {
      if (typeof value !== 'number') {
        throw new Error(this.$t('valueNotvalid'))
      }
      const roundedValue = Number(value.toFixed(2))
      if (Number.isNaN(roundedValue)) {
        return value
      }
      return roundedValue
    },
    cleanUsername,
    selectOrder(data) {
      this.next = true
      this.hideModal()
      this.orderId = data.orderId
      this.order = data.id
      this.selected = []
      this.products = data.products.edges
      this.products.forEach(element => {
        this.selected.push(element.node)
      })
      this.totalRefunded = data.totalRefunded

      if (this.totalRefunded) {
        this.totalAmount = this.roundToTwoDecimals(data.totalAmount - this.totalRefunded)
      } else {
        this.totalAmount = this.roundToTwoDecimals(data.totalAmount)
      }
      this.amount = data.shippingCost
      this.shippingCost = data.shippingCost
      try {
        this.vendorId = data.vendor.id
        this.vendorName = data.vendor.vendorName
      } catch (error) {
        showToast(this.$t('orderSellerError'), 2, this)
        this.next = false
      }
      try {
        this.userId = data.customer.id
        this.userName = data.customer.username
      } catch (error) {
        showToast(this.$t('orderBuyerError'), 2, this)
        this.next = false
      }
    },
    hideModal() {
      this.$refs['order-modal'].hide()
    },

    fetch() {
      const query = `            
        {
            allRefund(                       
                   id:"${this.id}") {
                    edges {
                    node {
                        id
                        status
                      
                       user {
                         id
                        username
                       }
                      amount
                      paymentGateway
                      reason
                      order {
                        id
                        orderId
                        totalAmount
                        totalRefunded
                        shippingCost
                        products {
                          edges {
                            node {
                              id
                              itemName
                              itemPrice
                              itemQuantity
                              
                            }
                          }
                        }
                      }
                      products {
                        edges {
                          node {
                            id
                            itemName
                            itemPrice
                            itemQuantity
                          }
                        }
                      }
                      paymentId                       
                      vendor {
                          id
                          vendorName
                                                      
                      }                   
                      createdAt
                      date
                    }
                }
                }
            }
         `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        const datos = res.data.data.allRefund.edges[0].node
        this.userId = datos.user?.id
        this.userName = datos.user?.username

        const productsRefund = datos.products.edges

        this.vendorId = datos.vendor?.id
        this.vendorName = datos.vendor?.vendorName
        this.totalRefunded = datos.order?.totalRefunded

        if (this.totalRefunded) {
          this.totalAmount = this.roundToTwoDecimals(datos.order.totalAmount - this.totalRefunded)
        } else {
          this.totalAmount = this.roundToTwoDecimals(datos.order.totalAmount)
        }

        this.shippingCost = datos.order?.shippingCost
        this.orderId = datos.order?.orderId
        this.order = datos.order?.id
        this.products = datos.order.products?.edges
        productsRefund.forEach(element => {
          this.selected.push(element.node)
        })

        this.statusPay = datos.status
        this.saveStatus = datos.status

        this.paymentGateway = datos.paymentGateway
        this.paymentId = datos.paymentId

        this.reason = datos.reason
        this.amount = datos.amount

        this.mostrarDatos = true
      }).catch(err => {
        console.log(err)
      })
    },

    updateData(value) {
      let query = ''
      const { id } = this.$route.params
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }

      if (value === 0) {
        this.showData = true
        query = `mutation{
                updateRefund(id:"${id}",input:{order:"${this.order}",vendor:"${this.vendorId}",user:"${this.userId}"}){
                    refund{
                    id
                    }
                }        
                }`
      } else {
        this.showData = true
        query = `mutation($reason: String){
                updateRefund(id:"${id}",input:{ reason: $reason, status:${this.statusPay},
                    paymentGateway:${this.paymentGateway}, amount:${this.amount},
                    products:[${this.getIdArray(this.selected)}]}){
                    refund{
                    id
                    }
                }
            }`
        const variables = {
          reason: this.reason,
        }
        data.append('variables', JSON.stringify(variables))
      }
      data.append('query', query)

      axios.post('', data, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'),
            1, this)
          this.showData = false
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.updateDataError'), 2, this)
          this.showData = false
        })
    },
    getIdArray(array) {
      const res = []
      array.forEach(element => {
        res.push(element.id)
      })
      return res
    },
    goBack() {
      window.history.back()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "vue-context/src/sass/vue-context";

#edit-vendor-shop .amount_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}
</style>
